/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
// import { CustomerState } from '@/store/customer/types';
import { SecretDesire } from '@/store/secretDesire/types';

const namespace: string = 'secretDesire';

export default class SecretDesireRequestPage extends Vue {
	@State('secretDesire') secretDesire: SecretDesire | undefined;
	@Action('getAllModels', {namespace}) getAllModels: any;
	@Action('storeSecretDesireModels', {namespace}) storeSecretDesireModels: any;
	@Action('fetchSecretDesireModels', {namespace}) fetchSecretDesireModels: any;
	@Mutation('setNumOfModels', {namespace}) setNumOfModels: any;
	@Mutation('setSelectedModelLength', {namespace}) setSelectedModelLength: any;
	@Mutation('setSelectedModels', {namespace}) setSelectedModels: any;
	@Getter('getNumOfModels', {namespace}) getNumOfModels: any;
	@Getter('getSelectedModels', {namespace}) getSelectedModels: any;

	numberOfModels: number = 0;
	timeVideo: number = 0;
	secret: string = '';
	username: string = '';
	email: string = '';
	phone: string = '';

	clickNumOfModels(operator: string) {
		this.numberOfModels = this.getNumOfModels;
		this.setSelectedModelLength(this.numberOfModels);
		if(operator === 'minus') {
			this.numberOfModels > 0 ? this.numberOfModels-- : this.numberOfModels = 0;
			this.setNumOfModels(this.numberOfModels);
			this.setSelectedModelLength(this.numberOfModels);

		}
		if(operator === 'plus') {
			this.numberOfModels++;
			this.setNumOfModels(this.numberOfModels);
			this.setSelectedModelLength(this.numberOfModels);
		}
	}
	removeModel(id: number) {
		// console.log(this.getSelectedModels);
		// const filteredModels = this.getSelectedModels.filter((item: any) => item.id === id );
		// this.setSelectedModels(filteredModels);
	}
	modelChoice() {
		this.$router.push({ name: 'secret-desire-model-choice' }).then(r => r);
	}
	onSubmit() {
		this.storeSecretDesireModels({
			timeVideo: this.timeVideo,
			scenario: this.secret,
			username: this.username,
			email: this.email,
			phone: this.phone
		})
		this.timeVideo = 0;
		this.secret = '';
		this.username = '';
		this.email = '';
		this.phone = '';
		this.$router.push({ name: 'info' }).then(r => r);
	}
	created() {
		this.fetchSecretDesireModels();
	}
}
